.popularPost .box {
  margin: 15px 15px 15px 0;
}
.popularPost img {
  width: 100%;
  height: 100%;
}
.economy-img {
  width: 150%;
  height: 100%;
}
.popularPost .text {
  padding: 20px;
}

.popularPost .date label {
  font-size: 35px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  color: #e02020; /* Use a noticeable color, such as tomato */
}
.popularPost .like {
  cursor: pointer;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
}

.popularPost .like i {
  margin-right: 5px;
  color: #395deb; /* Color of the like icon */
}

.popularPost .like label {
  font-size: 14px;
  color: #333;
}
