.music .box {
  margin-bottom: 30px;
  margin-left: 2px;
}
.music .text {
  padding: 0 30px;
}
.music .images {
  width: 320px;
  height: 220px;
}
.music img {
  width: 320px;
  height: 220px;
  object-fit: cover;
}
.music h1 {
  font-size: 22px;
}
@media screen and (max-width: 768px) {
  .music img,
  .music .images {
    height: 350px;
  }
}
.music .date label {
  font-size: 40px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  color: #e02020; /* Use a noticeable color, such as tomato */
}

.music .like {
  cursor: pointer;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
}

.music .like i {
  margin-right: 5px;
  color: #3762ee; /* Color of the like icon */
}

.music .like label {
  font-size: 14px;
  color: #333;
}
.share-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust as needed */
  transition: max-width 0.5s ease;
}
.title:hover {
  white-space: normal;
  max-width: none;
}