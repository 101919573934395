main {
  margin-top: 50px;
}
.details {
  padding-right: 50px;
}
.details .title {
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}
.details .author {
  display: flex;
  align-items: center;
  font-weight: 500;
  margin: 20px 0;
}
.details .author p {
  text-transform: capitalize;
  margin-right: 10px;
}
.details .author img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 10px;
}
.details .author label {
  color: grey;
}
.details .social {
  display: flex;
  margin-bottom: 20px;
}
.details .social .socBox {
  margin-right: 20px;
}
.details img {
  margin: 30px 0;
}
.desctop p {
  margin: 20px 0;
  line-height: 30px;
}
.descbot h1 {
  font-weight: 600;
  font-size: 30px;
  margin-top: 30px;
}
.quote {
  padding-left: 50px;
  border-left: 5px solid red;
}
.quote i {
  font-size: 50px;
  color: #2b31d7;
  margin-bottom: 20px;
}
.quote p {
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .details {
    padding-right: 0;
  }
  .details img {
    width: 100%;
  }
}
