@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
i {
  cursor: pointer;
}
a {
  text-decoration: none;
}
/*-----------global-----------*/
.container {
  max-width: 90%;
  margin: auto;
}
.flexSB {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex {
  display: flex;
}
.paddingTB {
  padding: 40px 0;
}
.paddingSmall {
  padding: 20px 0;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.category {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  padding: 5px 10px;
  letter-spacing: 2px;
  background-color: purple;
}
.row {
  width: 50%;
}
.images {
  position: relative;
}
.category1 {
  position: absolute;
  top: 0;
  left: 0;
  border-left: 5px solid #2b31d7;
  width: auto;
  display: block;
  background: linear-gradient(to right, #2b31d7 50%, black 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  cursor: pointer;
}
.category1 span {
  color: #fff;
  text-transform: capitalize;
  letter-spacing: 0;
}
.category1:hover {
  background-position: left bottom;
}
.category1 span:hover {
  color: #fff;
}
.title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 15px;
}
.comment i,
.date i {
  width: 30px;
  color: #2b31d7;
  font-size: 13px;
}

.comment label,
.date label {
  font-size: 13px;
  color: #868585;
}
.desc {
  font-size: 13px;
  margin: 10px 0;
}
.shadow {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 15px 10px -10px;
}

/*-----------global-----------*/
