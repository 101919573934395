/* .box {
    margin: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .img img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .text {
    padding-top: 10px;
  }
  
  .category {
    color: #555;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .titleBg {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
  }
  
  .author {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .author span {
    font-size: 1rem;
  }
   */
  .time-ago {
   
    font-weight: bold;
    color: #e02020;  /* Red color for prominence */
    padding: 1px 9px;
    background: #f9ecec;
    border-radius: 19px;
  }
  