.popular .box {
  background-color: #fff;
  display: flex;
  position: relative;
  margin: 15px 15px 15px 0;
}

.popular .images {
  position: relative;
  height: 150px;
}

.popular img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.popular .text h1 {
  padding: 20px 0 0 20px;
  margin: 0;
}

.popular .date {
  margin-top: 15px;
  padding: 0 0 0 20px;
}

.popular .date label {
  font-size: 25px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  color: #e02020; /* Use a noticeable color */
}

.popular .comment {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 0 0 20px;
  margin-top: 5px;
}

.popular .like {
  cursor: pointer;
  margin-left: 1px;
  display: inline-flex;
  align-items: center;
}

.popular .like i {
  margin-left: 20px;
  color: #474dff; /* Color of the like icon */
}

.popular .like label {
  font-size: 14px;
  color: #333;
}

.dropdown {
  position: absolute; /* Position it below the share icon */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 1000; /* Ensure it's above other content */
}

.dropdown button {
  display: block;
  width: 100%;
  background: none;
  border: none;
  padding: 5px;
  text-align: left;
  cursor: pointer;
}

.dropdown button:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

